<template>
  <!-- NAME[epic=动销] 搭赠统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="time">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="activity_id">
        <el-select
          v-model="form.activity_id"
          clearable
          style="width: 140px"
          placeholder="选择搭赠活动"
        >
          <el-option
            v-for="(i, idx) in activeSelect"
            :key="idx"
            :value="i.id"
            :label="i.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="请选择业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="goods_id">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="cust_id">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="selectClient"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="remark">
        <el-input v-model="form.remark" placeholder="备注"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleOrderDetail(row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getStaffList } from '@/api/setPrice'
  import { summaryList, getList } from '@/api/give'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'GiveSummary',
    components: { GoodsSearch, ClientSearch, saleOrderDetail },
    data() {
      return {
        loading: false,
        total: 0,
        staffList: [],
        activeSelect: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        time: [],
        form: {
          pageNo: 1, //页数
          pageSize: 10, //条数
          start_time: '', //开始时间2020-10-10
          end_time: '', //结束时间2020-11-10
          activity_id: '', //搭赠id
          goods_id: '', //商品id
          cust_id: '', //客户id
          staff_id: '', //业务员id
          remark: '', //备注
        },
        list: [],
        checkList: [
          '单号',
          '客户名称',
          '活动名称',
          // '主品',
          // '赠品',
          '单组销售金额',
          '单组搭赠货值',
          '销售组数',
          '销售金额',
          '搭赠货值',
          '业务员',
          '下单时间',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '180px',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '180px',
          },
          {
            order: 3,
            label: '活动名称',
            prop: 'title',
            width: '',
          },
          // {
          //   order: 4,
          //   label: '主品',
          //   prop: 'main_goods_count',
          //   width: '80px',
          // },
          // {
          //   order: 5,
          //   label: '赠品',
          //   prop: 'give_goods_count',
          //   width: '80px',
          // },
          {
            order: 6,
            label: '单组销售金额',
            prop: 'main_goods_money',
            width: '',
          },
          {
            order: 7,
            label: '单组搭赠货值',
            prop: 'give_goods_money',
            width: '',
          },
          {
            order: 8,
            label: '销售组数',
            prop: 'activity_count',
            width: '90px',
          },
          {
            order: 9,
            label: '销售金额',
            prop: 'main_money_total',
            width: '90px',
          },
          {
            order: 10,
            label: '搭赠货值',
            prop: 'give_money_total',
            width: '90px',
          },
          {
            order: 11,
            label: '业务员',
            prop: 'user_name',
            width: '',
          },
          {
            order: 12,
            label: '下单时间',
            prop: 'create_at',
            width: '150px',
          },
          {
            order: 13,
            label: '备注',
            prop: 'remark',
            width: '200px',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      getList({ pageSize: -1 }).then((res) => {
        if (res.code == 200) this.activeSelect = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await summaryList(this.form)
        console.log(data)
        this.total = totalCount
        this.list = data.list
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [7, 8]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      selectGoods(val) {
        this.form.goods_id = val.id
      },
      selectClient(val) {
        this.form.cust_id = val
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.time = []
        this.$refs.clientSearch.resetForm()
        this.$refs.goodsSearch.resetForm()
        this.form.goods_id = ''
        this.form.cust_id = ''
      },
      handleOrderDetail(row) {
        this.$refs['saleOrderDetail'].isshowDialog = true
        this.$refs['saleOrderDetail'].id = row.id
      },
      handleExport() {
        downloadFile(
          '/promoteAdmin/give-plan/form-export',
          '搭赠统计.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
